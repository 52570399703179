// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-easy-starter-js": () => import("/opt/build/repo/src/templates/easy-starter.js" /* webpackChunkName: "component---src-templates-easy-starter-js" */),
  "component---src-templates-email-form-js": () => import("/opt/build/repo/src/templates/email-form.js" /* webpackChunkName: "component---src-templates-email-form-js" */),
  "component---src-templates-flipping-card-js": () => import("/opt/build/repo/src/templates/flipping-card.js" /* webpackChunkName: "component---src-templates-flipping-card-js" */),
  "component---src-templates-facebook-link-js": () => import("/opt/build/repo/src/templates/facebook-link.js" /* webpackChunkName: "component---src-templates-facebook-link-js" */),
  "component---src-templates-simple-starter-js": () => import("/opt/build/repo/src/templates/simple-starter.js" /* webpackChunkName: "component---src-templates-simple-starter-js" */),
  "component---src-templates-quick-offer-js": () => import("/opt/build/repo/src/templates/quick-offer.js" /* webpackChunkName: "component---src-templates-quick-offer-js" */),
  "component---src-templates-simple-starter-11-js": () => import("/opt/build/repo/src/templates/simple-starter11.js" /* webpackChunkName: "component---src-templates-simple-starter-11-js" */),
  "component---src-templates-simple-starter-12-js": () => import("/opt/build/repo/src/templates/simple-starter12.js" /* webpackChunkName: "component---src-templates-simple-starter-12-js" */),
  "component---src-templates-simple-starter-14-js": () => import("/opt/build/repo/src/templates/simple-starter14.js" /* webpackChunkName: "component---src-templates-simple-starter-14-js" */),
  "component---src-templates-simple-starter-10-js": () => import("/opt/build/repo/src/templates/simple-starter10.js" /* webpackChunkName: "component---src-templates-simple-starter-10-js" */),
  "component---src-templates-simple-starter-15-js": () => import("/opt/build/repo/src/templates/simple-starter15.js" /* webpackChunkName: "component---src-templates-simple-starter-15-js" */),
  "component---src-templates-simple-starter-16-js": () => import("/opt/build/repo/src/templates/simple-starter16.js" /* webpackChunkName: "component---src-templates-simple-starter-16-js" */),
  "component---src-templates-simple-starter-17-js": () => import("/opt/build/repo/src/templates/simple-starter17.js" /* webpackChunkName: "component---src-templates-simple-starter-17-js" */),
  "component---src-templates-simple-starter-3-js": () => import("/opt/build/repo/src/templates/simple-starter3.js" /* webpackChunkName: "component---src-templates-simple-starter-3-js" */),
  "component---src-templates-simple-starter-2-js": () => import("/opt/build/repo/src/templates/simple-starter2.js" /* webpackChunkName: "component---src-templates-simple-starter-2-js" */),
  "component---src-templates-simple-starter-4-js": () => import("/opt/build/repo/src/templates/simple-starter4.js" /* webpackChunkName: "component---src-templates-simple-starter-4-js" */),
  "component---src-templates-simple-starter-5-js": () => import("/opt/build/repo/src/templates/simple-starter5.js" /* webpackChunkName: "component---src-templates-simple-starter-5-js" */),
  "component---src-templates-simple-starter-6-js": () => import("/opt/build/repo/src/templates/simple-starter6.js" /* webpackChunkName: "component---src-templates-simple-starter-6-js" */),
  "component---src-templates-simple-starter-7-js": () => import("/opt/build/repo/src/templates/simple-starter7.js" /* webpackChunkName: "component---src-templates-simple-starter-7-js" */),
  "component---src-templates-simple-starter-8-js": () => import("/opt/build/repo/src/templates/simple-starter8.js" /* webpackChunkName: "component---src-templates-simple-starter-8-js" */),
  "component---src-templates-simple-starter-9-js": () => import("/opt/build/repo/src/templates/simple-starter9.js" /* webpackChunkName: "component---src-templates-simple-starter-9-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-1-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-1/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-1-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-1-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-1/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-1-index-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-1-promopage-2-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-1/Promopage2.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-1-promopage-2-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-2-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-2/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-2-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-2-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-2/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-2-index-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-3-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-3/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-3-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-3-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-3/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-3-index-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-4-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-4/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-4-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-4-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-4/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-4-index-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-5-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-5/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-5-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-5-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-5/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-5-index-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-6-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-6/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-6-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-6-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-6/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-6-index-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-7-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-7/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-7-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-7-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-7/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-7-index-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-8-components-layout-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-8/components/Layout.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-8-components-layout-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-8-components-section-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-8/components/Section.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-8-components-section-js" */),
  "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-8-index-js": () => import("/opt/build/repo/src/pages/clients/dot-dot-data/beacons-in-the-city/page-8/index.js" /* webpackChunkName: "component---src-pages-clients-dot-dot-data-beacons-in-the-city-page-8-index-js" */),
  "component---src-pages-clients-mike-page-1-components-section-js": () => import("/opt/build/repo/src/pages/clients/mike/page-1/components/Section.js" /* webpackChunkName: "component---src-pages-clients-mike-page-1-components-section-js" */),
  "component---src-pages-clients-mike-page-1-index-js": () => import("/opt/build/repo/src/pages/clients/mike/page-1/index.js" /* webpackChunkName: "component---src-pages-clients-mike-page-1-index-js" */),
  "component---src-pages-clients-mike-page-4-components-section-js": () => import("/opt/build/repo/src/pages/clients/mike/page-4/components/Section.js" /* webpackChunkName: "component---src-pages-clients-mike-page-4-components-section-js" */),
  "component---src-pages-clients-mike-page-4-index-js": () => import("/opt/build/repo/src/pages/clients/mike/page-4/index.js" /* webpackChunkName: "component---src-pages-clients-mike-page-4-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

